/* body {
  font-family: Arial, sans-serif;
  margin: 20px;
  } */
  .container {
  max-width: 900px;
  margin: auto;
  }
  .dropdownGroup {
  display: inline-block;
  width: 45%;
  padding: 10px;
  vertical-align: top;
  }
  .dropdownGroup h3 {
  margin-top: 0;
  }
  .dropdown {
  margin: 10px 0;
  }
  .dropdownProject{
  margin: 10px 0;
  width: 44%;
  padding-left: 11px;
  }
  #results {
  margin-top: 20px;
  }
  .jsonDiff {
  border: 1px solid #ccc;
  padding: 10px;
  background: #f9f9f9;
  overflow-x: auto;
  }
  .jsonContainer {
  border: 1px solid #ccc;
  padding: 10px;
  background: #f0f0f0;
  height: 200px;
  overflow: auto;
  margin-top: 10px;
  }
  button {
  margin-top: 10px;
  }
  /* Custom styles for added, removed, and modified JSON nodes */
  .jsondiffpatchAdded {
  background-color: #d4edda;
  }
  .jsondiffpatchRemoved {
  background-color: #FF8C00;
  }
  .jsondiffpatchModified {
  background-color: #fff3cd;
  }
  .pTagStyle {
    font-size: 0.9em; color: #555;
  }
  .leftside{
    display: flex; justify-content: space-between;
  }
  .compareJson{
    display: flex; justify-content: flex-end; padding: 10px;
  }